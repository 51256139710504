import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <div className="Apps SubPage">
      <div className="Subpage__header">
        <h1>Apps</h1>
      </div>
      <div className="Apps__content">
        <div className="maxWidth">
          <h2>Coming Soon!</h2>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
